<template>
	<div class="gerencial_vendas">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Gerencial</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs3 sm4 lg3 class="pr-3">
						<v-autocomplete label="Status" v-model="filters.id_status" :items="data.listagemStatus" item-text="desc_status" item-value="id_status" multiple outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs3 sm4 lg3 class="pr-3">
                        <v-select :items="mesesComId" label="Meses" item-text="desc_mes" item-value="id_mes" v-model="filters.meses" multiple dense outlined clearable>
                        	<template v-slot:prepend-item>
								<v-list-item ripple @mousedown.prevent @click="toggleSelecionarTodosMeses">
									<v-list-item-action>
										<v-icon :color="filters.meses.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>Selecionar Tudo</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>
                        </v-select>
                    </v-flex>
                    <v-flex xs3 sm4 lg3 class="pr-3">
                        <v-select :items="data.listagemAnosComVendas" label="Ano" item-text="ano" item-value="ano" v-model="filters.ano" dense outlined clearable></v-select>
                    </v-flex>
                    <v-flex xs3 sm4 lg3 class="pr-3">
						<v-autocomplete label="Tipo Cliente" v-model="filters.id_tipo_cliente" :items="data.listagemTiposCliente" item-text="desc_tipo_cliente" item-value="id_tipo_cliente" multiple outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs3 sm4 lg3 class="pr-3">
						<v-autocomplete label="Cliente Pessoa Jurídica" v-model="filters.id_cliente_pj" :items="data.listagemClientesPessoaJuridica" item-text="desc_cliente" item-value="id_cliente" multiple outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs3 sm4 lg3 class="pr-3">
						<v-autocomplete label="Cliente Pessoa Física" v-model="filters.id_cliente_pf" :items="data.listagemClientesPessoaFisica" item-text="desc_cliente" item-value="id_cliente" multiple outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs3 sm4 lg3 class="pr-3">
						<v-autocomplete label="Produto" v-model="filters.id_produto" :items="data.listagemProdutos" item-text="desc_produto" item-value="id_produto" multiple outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-flex xs3 sm4 lg3 class="pr-3">
						<v-autocomplete label="Análises" v-model="filters.analises" :items="data.listagemAnalises" item-text="desc_analise" item-value="field_calculo" multiple outlined dense clearable></v-autocomplete>
					</v-flex>
                    <v-flex xs3 sm4 lg3 class="pr-3">
						<v-autocomplete label="Perspectivas" v-model="filters.agrupadores" :items="data.listagemAgrupadores" item-text="desc_agrupador" item-value="field_agrupador" multiple outlined dense clearable></v-autocomplete>
					</v-flex>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-flex xs3 sm4 lg3 class="pr-3" v-bind="attrs" v-on="on">
								<v-select label="Com valor que o cliente paga?" v-model="filters.valor_pagante" :items="['Não', 'Sim']" outlined dense v-if="filters.agrupadores.indexOf('identificacao_cliente') >= 0 && filters.agrupadores.indexOf('desc_produto') >= 0"></v-select>
								<v-select label="Com valor que o cliente paga?" v-model="filters.valor_pagante" :items="['Não']" outlined dense disabled v-else></v-select>
							</v-flex>
						</template>
						<span>Para permitir trazer o valor, as perspectivas de Cliente e Produto devem estar selecionadas</span>
					</v-tooltip>

					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
						<v-btn depressed dark color="indigo darken-4" class="ml-2" @click="aplicarFiltro">
                            <v-icon left>mdi-filter</v-icon>
                            <span>Filtrar</span>
                        </v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-data-table
					:headers="headersTable"
					:items="data.listagemRelatorio"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:[`item.valor_mes1`]="{ item }">R$ {{v_number_format(item.valor_mes1, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes2`]="{ item }">R$ {{v_number_format(item.valor_mes2, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes3`]="{ item }">R$ {{v_number_format(item.valor_mes3, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes4`]="{ item }">R$ {{v_number_format(item.valor_mes4, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes5`]="{ item }">R$ {{v_number_format(item.valor_mes5, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes6`]="{ item }">R$ {{v_number_format(item.valor_mes6, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes7`]="{ item }">R$ {{v_number_format(item.valor_mes7, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes8`]="{ item }">R$ {{v_number_format(item.valor_mes8, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes9`]="{ item }">R$ {{v_number_format(item.valor_mes9, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes10`]="{ item }">R$ {{v_number_format(item.valor_mes10, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes11`]="{ item }">R$ {{v_number_format(item.valor_mes11, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_mes12`]="{ item }">R$ {{v_number_format(item.valor_mes12, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_periodo`]="{ item }">R$ {{v_number_format(item.valor_periodo, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_total`]="{ item }">R$ {{v_number_format(item.valor_total, 2, ',', '.' )}}</template>
                    <template v-slot:[`item.valor_que_paga`]="{ item }">{{item.valor_que_paga != null ? `R$ ${v_number_format(item.valor_que_paga, 2, ',', '.' )}` : ''}}</template>
				</v-data-table>
				<v-flex xs12 class="text-end">
					<v-btn depressed dark color="indigo darken-4" class="my-5" @click="exportarListagemGeral(data.listagemRelatorio)">
						<v-icon>file_download</v-icon>
						<span>Exportar planilha</span>
					</v-btn>
				</v-flex>
			</v-flex>
		</v-layout>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario')),
				id_usuario: Number(localStorage.getItem('id_usuario'))
			},
			data: {
				listagemRelatorio: [{ano: 'Buscando...'}],
				listagemAnosComVendas: [String(new Date().getFullYear())],
				listagemStatus: [{desc_status: 'Buscando...'}],
				listagemTiposCliente: [{desc_tipo_cliente: 'Buscando...'}],
				listagemProdutos: [{desc_produto: 'Buscando...'}],
				listagemClientesPessoaFisica: [{desc_cliente: 'Buscando...'}],
				listagemClientesPessoaJuridica: [{desc_cliente: 'Buscando...'}],
				listagemAgrupadores: [
					{desc_agrupador: 'Data da Venda', field_agrupador: 'data_venda'},
					{desc_agrupador: 'Status', field_agrupador: 'desc_status'},
					{desc_agrupador: 'Pessoa Física / Jurídica', field_agrupador: 'pf_pj'},
					{desc_agrupador: 'Cliente', field_agrupador: 'identificacao_cliente'},
					{desc_agrupador: 'Produto', field_agrupador: 'desc_produto'},
					{desc_agrupador: 'Forma de Pagamento', field_agrupador: 'forma_pagamento'}
				],
				listagemAnalises: [
					{desc_analise: 'Valor', field_calculo: 'valor'},
					{desc_analise: 'Quantidade', field_calculo: 'quantidade'}
				]
			},
			filters: {
				id_status: [],
				id_tipo_cliente: [],
				meses: [new Date().getMonth() + 1],
				ano: String(new Date().getFullYear()),
				agrupadores: [],
				analises: ['valor'],
				id_produto: [],
				id_cliente_pj: [],
				id_cliente_pf: [],
				valor_pagante: ''
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,

			meses: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
            headersValoresTotaisCampos: [
                'valor_periodo',
                'valor_total'
            ],
            headersValoresTotaisDescricoes: {
                valor_periodo: 'Período Selecionado',
                valor_total: 'Total Ano'
            },
            headersQtdTotaisCampos: [
                'qtd_periodo',
                'qtd_total'
            ],
            headersQtdTotaisDescricoes: {
                qtd_periodo: 'Período Selecionado',
                qtd_total: 'Total Ano'
            },
            headersTable: []
		}
	},
	watch: {},
	methods: {
		toggleSelecionarTodosMeses () {
			this.$nextTick(() => {
				if (this.todosMesesSelecionados) {
					this.filters.meses = []
				} else {
					this.filters.meses = this.mesesComId.map(function(item){
						return item.id_mes;
					});
				}
			});
		},
		listagemHeaders() {
            var thisEl = this;
            let headers = [];

            if (thisEl.filters.agrupadores.indexOf('data_venda') >= 0) {
        		headers.push({
                	text: 'Data da Venda',
                	value: 'data_venda',
                	class: `primary white--text`
                });
        	} else {
        		headers.push({
	            	text: 'Ano',
	            	value: 'ano',
	            	class: `primary white--text`
	            });
        	}

            thisEl.data.listagemAgrupadores.forEach(function(agrupador, key){
            	if (agrupador.field_agrupador == 'data_venda') {
            		return;
            	}

            	if (thisEl.filters.agrupadores.indexOf(agrupador.field_agrupador) >= 0) {
            		headers.push({
	                	text: agrupador.desc_agrupador,
	                	value: agrupador.field_agrupador,
	                	class: `primary white--text`
	                });
            	}
            });

            thisEl.mesesComId.forEach(function(mes, key){
            	if (thisEl.filters.meses.indexOf(mes.id_mes) >= 0) {
            		if (thisEl.filters.analises.indexOf('valor') >= 0) {
            			headers.push({
		                	text: `Valor ${mes.desc_mes}`,
		                	value: `valor_mes${mes.id_mes}`,
		                	class: `primary white--text`
		                });
            		}
	                
	                if (thisEl.filters.analises.indexOf('quantidade') >= 0) {
            			headers.push({
		                	text: `Qtd. ${mes.desc_mes}`,
		                	value: `qtd_mes${mes.id_mes}`,
		                	class: `primary white--text`
		                });
            		}
                }
            });

            if (thisEl.filters.analises.indexOf('valor') >= 0) {
	            thisEl.headersValoresTotaisCampos.forEach(function(field, key2){
	                headers.push({
	                	text: eval(`thisEl.headersValoresTotaisDescricoes.${field}`),
	                	value: field,
	                	class: `primary white--text`
	                });
	            });
	        }

	        if (thisEl.filters.analises.indexOf('quantidade') >= 0) {
	            thisEl.headersQtdTotaisCampos.forEach(function(field, key2){
	                headers.push({
	                	text: eval(`thisEl.headersQtdTotaisDescricoes.${field}`),
	                	value: field,
	                	class: `primary white--text`
	                });
	            });
	        }

	        if (
	        	thisEl.filters.agrupadores.indexOf('identificacao_cliente') >= 0
	        	&& thisEl.filters.agrupadores.indexOf('desc_produto') >= 0
	        	&& thisEl.filters.valor_pagante == 'Sim'
	        ) {
	        	headers.push({
                	text: 'Valor que o cliente paga',
                	value: 'valor_que_paga',
                	class: `primary white--text`
                });
	        }

            this.headersTable = headers;
        },
        aplicarFiltro() {
        	var thisEl = this;
        	this.buscaListaDefault(thisEl.filters);
        },
		exportarListagemGeral(dadosListagem) {
			let thisEl = this;
			let dados = {
				headers: [],
			};

			if (thisEl.filters.agrupadores.indexOf('data_venda') >= 0) {
        		dados.headers.push({name: 'data_venda'});
        	} else {
        		dados.headers.push({name: 'ano'});
        	}

        	thisEl.data.listagemAgrupadores.forEach(function(agrupador, key){
            	if (agrupador.field_agrupador == 'data_venda') {
            		return;
            	}

            	if (thisEl.filters.agrupadores.indexOf(agrupador.field_agrupador) >= 0) {
            		dados.headers.push({name: agrupador.field_agrupador});
            	}
            });

            thisEl.mesesComId.forEach(function(mes, key){
            	if (thisEl.filters.meses.indexOf(mes.id_mes) >= 0) {
            		if (thisEl.filters.analises.indexOf('valor') >= 0) {
            			dados.headers.push({name: `valor_mes${mes.id_mes}`});
            		}
	                
	                if (thisEl.filters.analises.indexOf('quantidade') >= 0) {
	                	dados.headers.push({name: `qtd_mes${mes.id_mes}`});
            		}
                }
            });

            if (thisEl.filters.analises.indexOf('valor') >= 0) {
	            thisEl.headersValoresTotaisCampos.forEach(function(field, key2){
	            	dados.headers.push({name: field});
	            });
	        }

	        if (thisEl.filters.analises.indexOf('quantidade') >= 0) {
	            thisEl.headersQtdTotaisCampos.forEach(function(field, key2){
	            	dados.headers.push({name: field});
	            });
	        }
			
			// dados.headers = Object.keys(dadosListagem[0]).map((item) => {
			// 	if (item.indexOf('mes') >= 0) {//tradução das labels de mês
			// 		return {name: item, label: item.substr(0, item.indexOf('mes')) + this.meses[item.substr(item.indexOf('mes') + 3) - 1]};
			// 	} else {
			// 		return {name: item};
			// 	}
			// });

			dados.values = dadosListagem;

			let numericFields = ['valor_que_paga'];
			thisEl.mesesComId.forEach(function(mes, key){
            	if (thisEl.filters.meses.indexOf(mes.id_mes) >= 0) {
	                numericFields.push(`valor_mes${mes.id_mes}`);
                }
            });

            numericFields.push(`valor_periodo`);
            numericFields.push(`valor_total`);

			this.exportXlsAjax(dados, 'relatorio_gerencial_vendas', numericFields);
		},
		exportXlsAjax(dados, filename, numericHeaders){

			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
            var headersLabels = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	
	        	headers.push(dados.headers[i].name);

	        	if (dados.headers[i].label != undefined) {
	        		headersLabels.push(dados.headers[i].label);
	        	} else {
	        		headersLabels.push(dados.headers[i].name);
	        	}
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headersLabels])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		limparFiltro() {
			this.filters = {
				id_status: [],
				id_tipo_cliente: [],
				meses: [new Date().getMonth() + 1],
				ano: String(new Date().getFullYear()),
				agrupadores: [],
				analises: ['valor'],
				id_produto: [],
				id_cliente_pj: [],
				id_cliente_pf: [],
				valor_pagante: ''
			};

			this.buscaListaDefault();
		},
		v_number_format (number, decimals, dec_point, thousands_sep) {
			return number_format(number, decimals, dec_point, thousands_sep);
		},
		buscaListaDefault(post = {}) {
			var thisEl = this;

			thisEl.data.listagemRelatorio = [{ano: 'Buscando...'}];

			var dataToSend = post;
			dataToSend.token = localStorage.getItem('token');

			thisEl.$http.post(//requisição ajax
	            'venda/listagem_relatorio_gerencial_vendas',
	            dataToSend,
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemRelatorio = r.body.listagemRelatorio;
	                	thisEl.filters.ano = r.body.filters.ano;
	                	thisEl.filters.meses = r.body.filters.meses.map(function(mes){
	                		return Number(mes);
	                	});

	                	thisEl.listagemHeaders();
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'venda/listagem_filtros_relatorio_gerencial',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemAnosComVendas = r.body.listagemAnosComVendas;
	                	thisEl.data.listagemStatus = r.body.listagemStatus;
	                	thisEl.data.listagemTiposCliente = r.body.listagemTiposCliente;
	                	thisEl.data.listagemProdutos = r.body.listagemProdutos;
	                	thisEl.data.listagemClientesPessoaFisica = r.body.listagemClientesPessoaFisica;
	                	thisEl.data.listagemClientesPessoaJuridica = r.body.listagemClientesPessoaJuridica;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		}
	},
	computed: {
		mesesComId() {
            var obj = [];

            this.meses.forEach(function(item, key){
                obj.push({id_mes: key+1, desc_mes: item});
            });

            return obj;
        },
        todosMesesSelecionados () {
			return this.filters.meses.length === this.mesesComId.length
		},
		algunsMesesSelecionados () {
			return this.filters.meses.length > 0 && !this.todosMesesSelecionados
		},
		icon () {
			if (this.todosMesesSelecionados) return 'mdi-close-box'
			if (this.algunsMesesSelecionados) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
